<template>
    <div class="page success">
        <div class="body">
            <div class="header">
                <img src="../assets/success.png" alt="">
            </div>
            <div class="title">兑换成功</div>
            <div class="desc">订单编号 <span>{{this.$route.query.orderId}}</span></div>
            <div class="group">
                <span @click="goOrder">查看兑换订单</span>
                <span @click="goHome">继续领取当前权益</span>
            </div>
        </div>
        <div class="bottom">
            <BottomCom/>
        </div>
    </div>
</template>

<script>
    import BottomCom from "../components/BottomCom";

    export default {
        name: "Success",
        components: {BottomCom},
        methods: {
            async goHome() {
                await this.$router.push('/')
            },
            async goOrder() {
                await this.$router.push('/Order')
            },
        }
    }
</script>

<style scoped>
    .success {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        padding: 24px;
        box-sizing: border-box;
    }

    .success .body {
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .success .body .header {
        margin-top: 40px;
        width: 180px;
        height: 194px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .success .body .header img {
        max-width: 180px;
        width: 100%;
    }

    .success .body .title {
        margin-top: 20px;
        font-size: 36px;
        font-weight: bold;
        color: #333333;
    }

    .success .body .desc {
        margin-top: 20px;
        font-size: 30px;
        font-weight: 400;
        color: #333333;
    }

    .success .body .desc span {
        margin-left: 15px;
    }

    .success .body .group {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;
    }

    .success .body .group span {
        margin: 0 20px;
        height: 80px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    .success .body .group span:first-child {
        border: 2px solid #222529;
        font-size: 28px;
        font-weight: 500;
        color: #212429;
    }

    .success .body .group span:last-child {
        border: 2px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        font-size: 28px;
        font-weight: 500;
        color: #E2BE93;
    }


    .success .bottom {
        width: 642px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

</style>
