<template>
    <div class="bottomCom">
<!--        <div class="title">️-->
<!--            <div class="img"><img src="../assets/icon_line.png" alt=""/></div>-->
<!--            <div class="text">权益服务热线</div>-->
<!--            <div class="img"><img src="../assets/icon_line.png" alt=""/></div>-->
<!--        </div>-->
        <div class="title"><img src="../assets/icon_line.png" alt=""/>权益服务热线<img src="../assets/icon_line.png" alt=""/></div>
        <a class="top" href="tel:400-608-1001">400-608-1001</a>
        <div class="bottom">服务时间：工作日9:30-18:00</div>
    </div>
</template>

<script>
    export default {
        name: "BottomCom"
    }
</script>

<style scoped>
    .bottomCom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bottomCom .title {
        font-size: 36px;
        font-weight: bold;
        margin-top: 20px;
        color: #222529;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .bottomCom .title img {
        width: 19px;
        height: 19px;
        margin: 0 20px;
    }

    .bottomCom .top {
        font-size: 36px;
        font-weight: bold;
        margin-top: 20px;
        color: #222529;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .bottomCom .bottom {
        margin-top: 20px;
        font-size: 26px;
        font-weight: 400;
        line-height: 37px;
        color: #222529;
    }
</style>
